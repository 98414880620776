import React, { FunctionComponent } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Global, PageContext, SiteMetadata } from '@/utils/types';

interface NotFoundPageProps {
  data: {
    strapiGlobal: Global;
    site: SiteMetadata;
  };
}

const NotFoundPage: FunctionComponent<NotFoundPageProps> = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
        ...siteMetadata
      },
    },
  } = data;

  const pageContext: PageContext = {
    id: '404',
    locale: defaultLocale,
    locales,
    defaultLocale,
    slug: '404',
    ...siteMetadata,
  };

  return (
    <>
      <SEO
        seo={{ metaTitle: '404: Not found' }}
        global={strapiGlobal}
        pageContext={pageContext}
      />
      <Layout pageContext={pageContext} global={strapiGlobal}>
        <div className="container dark:text-textlightblue my-8">
          <h1 className="heading2 mb-4">404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
        author {
          name
        }
        organization {
          logo
          name
          url
        }
        siteUrl
        image
        title
        description
      }
    }
    strapiGlobal {
      ...GlobalData
    }
  }
`;
